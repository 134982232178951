import React, {
    useEffect,
    useState
} from "react";
import moment from "moment";

import MerryChristmasScreen from "../screens/MerryChristmasScreen";
import PlanningScreen from "../screens/PlanningScreen";
import RotatingScreen from "../screens/RotatingScreen";
import DevelopLinksScreen from "../screens/DevelopLinksScreen";
import DevelopRechtsScreen from "../screens/DevelopRechtsScreen";

function AutoDisplaySwitching() {
    const [today, setToday] = useState(null);
    const [screen, setScreen] = useState(null);

    useEffect(() => {
        const callback = () => {
            const urlParams = new URLSearchParams(window.location.search);
            setScreen(urlParams.get("screen"));
            setToday(moment());
        };
        const interval = setInterval(callback, 60 * 1000);
        callback();

        return () => clearInterval(interval);
    }, []);

    if(!screen) {
        return null;
    }

    const day = today.format("MM-DD");
    const christmasDays = [
        "12-20",
        "12-21",
        "12-22",
        "12-23",
        "12-24"
    ];
    if((today.hour() > 18 || today.hour() < 5) && christmasDays.includes(day)) {
        return (
            <MerryChristmasScreen/>
        );
    }

    switch(screen) {
        case "monteur":
        case "kantine-links":
        case "service":
            return (
                <PlanningScreen/>
            );
        case "kantine-midden":
            return (
                <RotatingScreen/>
            );
        case "develop-links":
            return (
                <DevelopLinksScreen/>
            );
        case "develop-rechts":
            return (
                <DevelopRechtsScreen/>
            );
        default:
            return (
                <div className="w-100 text-center text-white">
                    <h1>Invalid screen</h1>
                </div>
            );
    }
}

export default React.memo(AutoDisplaySwitching);
